// BlogPage.jsx

import React from 'react';
import { Link } from 'react-router-dom'; // Import Link for navigation
import '../css/BlogPage.css';

const blogPosts = [
  {
    date: 'Apr 26, 2024',
    title: 'Getting Started with Git',
    link: '/blogs/getting-started-with-git', // Link to the specific blog page
  },
];

export default function BlogPage() {
  return (
    <div className="blog-page">
      <h1 className="blog-title">Blog</h1>
      <p className="blog-subtitle">
        {blogPosts.length} posts so far. Stay tuned for more!
      </p>

      <div className="blog-posts">
        {blogPosts.map((post, index) => (
          <div key={index} className="post-item">
            <span className="post-date">{post.date}</span>
            {/* Use Link for clickable titles */}
            <Link to={post.link} className="post-title">
              {post.title}
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}
