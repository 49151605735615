import React, { useEffect, useContext, useState } from 'react';
import '../css/about.css'; // Link to the About page styles
import '../css/navigation.css'; // Link to the Navigation styles
import { ThemeContext } from '../components/themecontext';

const About = () => {
  const { darkMode } = useContext(ThemeContext);

  const [expandedTile, setExpandedTile] = useState(null);

  useEffect(() => {
    const setTimeGreeting = () => {
      const greetingElement = document.getElementById('time-greeting');
      const hours = new Date().getHours();
      let greeting;

      if (hours >= 5 && hours < 12) {
        greeting = 'Hello, early bird!';
      } else if (hours >= 12 && hours < 17) {
        greeting = 'Hello, Good afternoon';
      } else if (hours >= 17 && hours < 21) {
        greeting = 'Hello, Good evening!';
      } else {
        greeting = 'Hello, Night Owl!';
      }

      if (greetingElement) {
        greetingElement.style.color = '#daa520';
        greetingElement.textContent = greeting;
      }
    };

    setTimeGreeting();
  }, []);

  const toggleDetails = (tileId) => {
    setExpandedTile(expandedTile === tileId ? null : tileId);
  };

  const isExpanded = (tileId) => expandedTile === tileId;

  const workExperiences = [
    {
      id: 'pixquid',
      company: 'Pixquid',
      role: 'Data Scientist',
      period: 'Aug 2024 - Present',
      location: 'Chicago, IL',
      details: [
        'Developed and deployed the Pixquid platform on Bubble, integrating Stripe and AWS S3.',
        'Built a custom upload tool for media, leveraging S3 for scalable storage and CloudFront for global delivery.',
        'Created custom JavaScript plugins and designed data analytics strategies for optimization.',
      ],
    },
    {
      id: 'vistex',
      company: 'Vistex',
      role: 'Data Scienctist Intern',
      period: 'Sept 2023 - Dec 2023',
      location: 'Chicago, IL',
      details: [
        'Optimized Python-driven data visualizations, cutting shareholder meeting time by 25%.',
        'Led a causal impact analysis for marketing campaigns, improving efficiency by 15%.',
        'Enhanced sales forecasting accuracy by 20% via ARIMA models.',
      ],
    },
    {
      id: 'istc',
      company: 'Illinois Science and Tech Coalition',
      role: 'Data Scientist Intern',
      period: 'June 2023 - Sept 2023',
      location: 'Chicago, IL',
      details: [
        'Streamlined data searches across U.S. educational databases with a custom Python and PowerBI tool.',
        'Improved data quality by 15% by enhancing validation and cleaning procedures.',
        'Increased forecasting accuracy by implementing ARIMA models.',
      ],
    },
    {
      id: 'vuedata',
      company: 'Vuedata Technologies',
      role: 'Software Engineer',
      period: 'Jun 2020 - Aug 2021',
      location: 'Chennai, IN',
      details: [
        'Launched a cross-platform fitness application for Android and iOS.',
        'Integrated Firebase and used Git for version control.',
        'Collaborated on UI/UX and leveraged Postman for API testing and Tableau for reporting.',
      ],
    },
  ];

  return (
    <div className={`about-page ${darkMode ? 'dark' : 'light'}`}>

      
      <div className="about-section">
      <div className="content-wrapper">
  <div className="left-column">
    <h1>About me<span className="color">:</span></h1>
    <div className="about-text">
      <p>
        <span id="time-greeting"></span>, I'm Dev! Originally from India,
        I'm now based in the vibrant city of Chicago.
      </p>
      <p>
        I’m a Data Scientist at Pixquid with a Master’s in Computer
        Science, specializing in Data Science, Full Stack Development,
        and Machine Learning.
      </p>
      <p>
        Outside of work, I’m passionate about photography, exploring new
        cuisines, and discovering unique spots around the city. When I’m
        not diving into data or behind the lens, you can find me gaming,
        connecting with friends, or adding to my collection of Hot
        Wheels.
      </p>
    </div>
  </div>
  
  <div className="right-column">
    <img src="./potrait.jpeg" alt="Dev Portrait" className="portrait-image" />
  </div>
</div>

      </div>

      <div className="section timeline">
      <h4 className="timeline-title">
          Work<span className="color"> :</span>
        </h4>
        <div className="timeline-container">
          {workExperiences.map((item) => (
            <React.Fragment key={item.id}>
              <div className="timeline-block">
                <div className="timeline-logo">
                  <img src={`/${item.id}.jpg`} alt={`${item.company} Logo`} />
                </div>
                <div className="timeline-content">
                  <h4>{item.role}</h4>
                  <p>{item.company}</p>
                </div>
                <span className="timeline-date">{item.period}</span>
                <button
                  className={`plus-button ${isExpanded(item.id) ? 'open' : ''}`}
                  onClick={() => toggleDetails(item.id)}
                >
                  {isExpanded(item.id) ? 'X' : '+'}
                </button>
              </div>
              {isExpanded(item.id) && (
                <div className="timeline-details">
                  <h2>{item.company}</h2>
                  <h3>{item.role}</h3>
                  <p>{item.location} &nbsp;&nbsp;&nbsp;&nbsp; {item.period}</p>
                  <ul>
                    {item.details.map((detail, idx) => (
                      <li key={idx}>{detail}</li>
                    ))}
                  </ul>
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default About;
