import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ThemeContext } from '../components/themecontext';
import '../css/Header.css';

const Header = () => {
  const { darkMode, toggleDarkMode } = useContext(ThemeContext);
  const [menuOpen, setMenuOpen] = useState(false);
  const [isoAnimated, setIsoAnimated] = useState(false);

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add('dark-mode');
      document.body.classList.remove('light-mode');
    } else {
      document.body.classList.add('light-mode');
      document.body.classList.remove('dark-mode');
    }
  }, [darkMode]);

  const toggleMenu = () => setMenuOpen(!menuOpen);

  const handleIsoClick = () => {
    setIsoAnimated(true);
    toggleDarkMode();
    setTimeout(() => setIsoAnimated(false), 500);
  };

  return (
    <header className="header">
      <div className="header-inner">
        {/* Left: DM */}
        <div className="header-left">
          <Link to="/" className="logo-text">
            DM
          </Link>
        </div>

        {/* Center: OPEN FOR WORK */}
        <div className="header-center">
          <div className="open-for-work">
            <span className="green-dot"></span>
            <span>OPEN FOR WORK</span>
          </div>
        </div>

        {/* Right: Desktop nav + ISO + Mobile menu button (hidden on desktop) */}
        <div className="header-right">
          {/* Desktop navigation */}
          <nav className="nav-desktop">
            <ul>
              <li>
                <Link to="/about" onClick={() => setMenuOpen(false)}>About</Link>
              </li>
              <li>
                <Link to="/gallery" onClick={() => setMenuOpen(false)}>Gallery</Link>
              </li>
              <li>
                <Link to="/blogspage" onClick={() => setMenuOpen(false)}>Blogs</Link>
              </li>
            </ul>
          </nav>

          {/* ISO toggle */}
          <button
            className={`iso-toggle ${isoAnimated ? 'animated' : ''}`}
            onClick={handleIsoClick}
            aria-label="Toggle ISO / Mode"
          >
            ISO : {darkMode ? '800' : '100'}
          </button>

          {/* Mobile menu button (hidden on desktop) */}
          <button
            className={`menu-toggle ${menuOpen ? 'active' : ''}`}
            onClick={toggleMenu}
            aria-label="Toggle Menu"
          >
            {menuOpen ? 'X' : 'MENU'}
          </button>
        </div>
      </div>

      {/* Mobile nav overlay */}
      {menuOpen && (
        <nav className="nav-mobile">
          <div className="nav-section">
            <h2>[DIRECTORY]</h2>
            <ul className="directory-list">
              <li>
                <Link to="/about" onClick={() => setMenuOpen(false)}>
                  <span className="menu-index">[01]</span> ABOUT
                </Link>
              </li>
              <li>
                <Link to="/gallery" onClick={() => setMenuOpen(false)}>
                  <span className="menu-index">[02]</span> GALLERY
                </Link>
              </li>
              <li>
                <Link to="/blogspage" onClick={() => setMenuOpen(false)}>
                  <span className="menu-index">[03]</span> BLOGS
                </Link>
              </li>
            </ul>
          </div>
          <div className="nav-section contact-section">
            <h2>[CONTACT]</h2>
            <p>
              <span className="contact-label">[E]</span>
              <a
                href="mailto:devsendatham@gmail.com?subject=Contact&body=Hello, I wanted to reach out regarding..."
                className="contact-email"
              >
                devsendatham@gmail.com
              </a>
            </p>
          </div>
          <div className="nav-section socials-section">
            <h2>[SOCIALS]</h2>
            <ul className="socials-list">
              <li>
                <a
                  href="https://www.linkedin.com/in/devsendatham"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  LINKEDIN
                </a>
              </li>
              <li>
                <a
                  href="https://huggingface.co/Devsendatham"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  HUGGING FACE
                </a>
              </li>
              <li>
                <a
                  href="/Devsen%20Datha%20Mukkamala%20Resume.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  RESUME
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/devexif"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  INSTAGRAM
                </a>
              </li>
            </ul>
          </div>
          <div className="location">CHICAGO, ILLINOIS</div>
        </nav>
      )}
    </header>
  );
};

export default Header;
