import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import HeroSection from "./components/HeroSection";
import Footer from "./components/footer";
import About from "./pages/about";
import Gallery from "./pages/gallery";
import BlogPage from "./pages/blogspage";
import ResumePage from "./pages/resume";
import ProgressBar from "./components/navigation";
import BCC from "./pages/projects/bcc";
import "./css/transitions.css"; // Add CSS for transitions
import "./css/global.css"; // Global CSS
import GettingStartedWithGit from "./pages/blogs/getting-started-with-git";

const App = () => {
  return (
    <Router>
      <div className="app-container">
        <Header />
        <ProgressBar />
        <Routes>
          {/* General Routes */}
          <Route path="/" element={<HeroSection />} />
          <Route path="/about" element={<About />} />
          <Route path="/resume" element={<ResumePage />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/blogspage" element={<BlogPage />} />

          {/* Blog Routes */}
          <Route
            path="/blogs/getting-started-with-git"
            element={<GettingStartedWithGit />}
          />

          {/* Project Routes */}
          <Route path="/projects/bcc" element={<BCC />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
