import React, { useContext } from "react";
import { ThemeContext } from "../../components/themecontext";
import histogramImage from '../../assets/bcc/image.png';
import pieChartImage from '../../assets/bcc/image copy.png';
import barPlotImage from '../../assets/bcc/image copy 2.png';
import '../../css/projects.css'

const BreastCancerClassification = () => {
  const { darkMode } = useContext(ThemeContext);

  return (
    <div className={`project-page ${darkMode ? "dark-mode" : "light-mode"}`}>
      {/* Breadcrumb Navigation */}
      <div className="breadcrumb">
        <a href="/blog">← Back to Blog</a>
      </div>

      {/* Title Section */}
      <header className="blog-header">
        <h1>Breast Cancer Classification Using Machine Learning</h1>
        <p>
          2024, Data Science Project by <strong>Devsen Datha Mukkamala</strong>
        </p>
      </header>

      {/* Introduction */}
      <section className="introduction">
        <h2>Introduction</h2>
        <p>
          Breast cancer is one of the most common cancers affecting women
          worldwide. Early detection is crucial for effective treatment and
          improved survival rates. This project develops a machine learning
          model to classify breast cancer tumors as malignant or benign using
          tumor-related features.
        </p>
        <pre>
          <code>
            {`
# Importing Necessary Libraries
import pandas as pd
import numpy as np
import matplotlib.pyplot as plt
import seaborn as sns
from sklearn.model_selection import train_test_split
from sklearn.metrics import accuracy_score, classification_report, roc_auc_score
            `}
          </code>
        </pre>
      </section>

      {/* Dataset Information */}
      <section className="dataset-info">
        <h2>Dataset Information</h2>
        <p>
          Sourced from Kaggle, the dataset includes features such as tumor size,
          patient age, and other medical attributes. The dataset is well-structured
          with no missing values, ready for analysis and model development.
        </p>
        <pre>
          <code>
            {`
# Loading the Dataset
data = pd.read_csv('breast_cancer_data.csv')
print(data.info())
print(data.describe())
            `}
          </code>
        </pre>
      </section>

      {/* Data Visualizations */}
      <section className="data-visualizations">
        <h2>Data Visualizations</h2>
        <figure>
          <img src={histogramImage} alt="2D Histogram" />
          <figcaption>2D Histogram: Age vs Tumor Size</figcaption>
        </figure>
        <figure>
          <img src={pieChartImage} alt="Pie Chart" />
          <figcaption>Pie Chart: Tumor Stages</figcaption>
        </figure>
        <figure>
          <img src={barPlotImage} alt="Bar Plot" />
          <figcaption>Bar Plot: Race Distribution</figcaption>
        </figure>
        <pre>
          <code>
            {`
# Example of Visualization Code
plt.hist2d(data['Age'], data['Tumor_Size'], bins=20, cmap='viridis')
plt.colorbar()
plt.xlabel('Age')
plt.ylabel('Tumor Size')
plt.title('2D Histogram: Age vs Tumor Size')
plt.show()
            `}
          </code>
        </pre>
      </section>

      {/* Machine Learning Models */}
      <section className="ml-models">
        <h2>Machine Learning Models</h2>
        <ul>
          <li>Decision Tree Classifier: Accuracy ~79.5%</li>
          <li>Random Forest Classifier: Accuracy ~87.6%</li>
          <li>Support Vector Machine: Accuracy ~80.9%</li>
          <li>Gradient Boosting: Accuracy ~86.7%</li>
          <li>Logistic Regression: Accuracy ~79.8%</li>
        </ul>
        <pre>
          <code>
            {`
# Random Forest Classifier Example
from sklearn.ensemble import RandomForestClassifier

# Training and Testing Split
X_train, X_test, y_train, y_test = train_test_split(X, y, test_size=0.2, random_state=42)

# Model Training
rf_model = RandomForestClassifier()
rf_model.fit(X_train, y_train)

# Evaluation
y_pred = rf_model.predict(X_test)
accuracy = accuracy_score(y_test, y_pred)
print("Random Forest Accuracy:", accuracy)
            `}
          </code>
        </pre>
      </section>

      {/* Hyperparameter Tuning */}
      <section className="hyperparameter-tuning">
        <h2>Hyperparameter Tuning</h2>
        <p>
          Techniques like Grid Search, SMOTE for class balancing, and early
          stopping for model optimization were employed. The final Random Forest
          model achieved the highest accuracy of 87.6%.
        </p>
        <pre>
          <code>
            {`
# Using GridSearchCV for Hyperparameter Tuning
from sklearn.model_selection import GridSearchCV

param_grid = {
    'n_estimators': [50, 100, 200],
    'max_depth': [10, 20, None],
    'min_samples_split': [2, 5, 10]
}

grid_search = GridSearchCV(RandomForestClassifier(), param_grid, cv=5)
grid_search.fit(X_train, y_train)
print("Best Parameters:", grid_search.best_params_)
            `}
          </code>
        </pre>
      </section>

      {/* Conclusion */}
      <section className="conclusion">
        <h2>Conclusion</h2>
        <p>
          The project demonstrates the effectiveness of machine learning models
          in breast cancer detection. The Random Forest classifier achieved the
          highest accuracy of 87.6%, showing robust handling of class imbalance
          and high feature importance.
        </p>
      </section>
    </div>
  );
};

export default BreastCancerClassification;
