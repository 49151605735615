import React from 'react';
import '../css/gallery.css';


const Gallery = () => {
  const galleryItems = [
    { id: "one", title: "Mclaren 720S LW///01", img: "/photo2.jpeg" },
    { id: "two", title: "Mclaren 570S///002", img: "/photo3.jpg" },
    { id: "three", title: "Toyota GR 86///003", img: "/photo4.jpg"},

  ];

  return (
    <div className="gallery-wrapper" id="sliderOne">
      
      <h1 className="gallery-title">Gallery</h1>
      <div className="gallery" id="slider-inner1">
        {galleryItems.map((item) => (
          <div key={item.id} className="item">
            <h2 id={item.id} className="gallery-item-title">
              {item.title}
            </h2>
            {/* Lazy loading applied */}
            <img src={item.img} alt={item.alt} className="gallery-image" loading="lazy" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Gallery;


