import React, { useContext } from 'react';
import { ThemeContext } from '../components/themecontext'; // Adjust path as needed
import '../css/hero.css'; // Ensure you add styles for the hero section

const HeroSection = () => {
  const { darkMode } = useContext(ThemeContext);

  return (
    <div className={`hero-container ${darkMode ? 'dark' : 'light'}`}>
      {/* Main Hero Section */}
      <main className="hero-main">
        <div className="hero-description">
          <span>Data Scientist | Photographer</span>
        </div>
        <h1 className="hero-greeting">Devsen Datha Mukkamala</h1>
        <a href="https://www.linkedin.com/in/devsendatham" className="hero-cta-button">
          Connect with me
        </a>
      </main>
    </div>
  );
};

export default HeroSection;
