import React from 'react';
import authorImage from '../../assets/potrait.jpeg'; // Your portrait image
import '../../css/blog.css';

const GettingStartedWithGit = () => {
  return (
    <div className="blog-container">
      <div className="blog-back-link">
        <a href="/blog">← All posts</a>
      </div>

      <article className="blog-article">
        <header className="blog-header">
          <h1 className="blog-title">Getting Started with Git</h1>
          <div className="blog-meta">
            <time dateTime="2024-12-14">December 14, 2024</time> · 7 min read
          </div>
        </header>
        
        <div className="blog-author">
  <img src={authorImage} alt="Author portrait" />
  <div className="author-details">
    <span className="author-name">Devsen Datha Mukkamala</span>
    <time className="author-date" dateTime="2022-04-27">April 27, 2024</time>
  </div>
</div>

        <p>
          Welcome to the ultimate beginner’s guide to Git! Whether you’re new to
          coding or looking to level up your skills, this guide will walk you
          through the basics of Git—a must-have tool for every developer.
        </p>

        <h2>What is Git?</h2>
        <p>
          Git is a distributed version control system. It keeps track of changes
          in your project files, helping you collaborate with others and manage
          your code effectively. Unlike copying files manually, Git maintains a
          history of every change, making it easy to roll back if something goes
          wrong.
        </p>
        <p>
          Imagine working on a group project where everyone edits the same
          document. Without a tool like Git, chaos can ensue! Git ensures
          everyone’s contributions are tracked and merged seamlessly.
        </p>

        <h2>Why Use Git?</h2>
        <ul>
          <li>
            <strong>Version Control:</strong> Keep a history of changes, and go
            back in time if needed.
          </li>
          <li>
            <strong>Collaboration:</strong> Work with teams without overwriting
            each other’s changes.
          </li>
          <li>
            <strong>Experimentation:</strong> Try new features in branches
            without affecting the main project.
          </li>
        </ul>

        <h2>Getting Started: Step-by-Step Guide</h2>
        <ol>
          <li>
            <strong>Install Git:</strong> Download Git from the{' '}
            <a
              href="https://git-scm.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              official website
            </a>{' '}
            and follow the installation instructions.
          </li>
          <li>
            <strong>Set Up Git:</strong> Configure your username and email:
            <pre><code>git config --global user.name "Your Name"
git config --global user.email "you@example.com"</code></pre>
          </li>
          <li>
            <strong>Create a Repository:</strong> Initialize a new project:
            <pre><code>git init</code></pre>
          </li>
          <li>
            <strong>Track Changes:</strong> Add files and commit them:
            <pre><code>git add .
git commit -m "Initial commit"</code></pre>
          </li>
          <li>
            <strong>Connect to a Remote Repository:</strong> Push your code to
            GitHub:
            <pre><code>git remote add origin https://github.com/username/repository.git
git push -u origin main</code></pre>
          </li>
        </ol>

        <h2>Key Concepts</h2>
        <ul>
          <li>
            <strong>Repositories:</strong> Think of these as your project
            folders, where Git tracks all changes.
          </li>
          <li>
            <strong>Commits:</strong> Snapshots of your project at specific
            points in time.
          </li>
          <li>
            <strong>Branches:</strong> Work on new features without disrupting
            the main project.
          </li>
          <li>
            <strong>Merge:</strong> Combine changes from branches into the main
            project.
          </li>
        </ul>

        <h2>Conclusion</h2>
        <p>
          Git is a game-changer for developers. By mastering its commands and
          best practices, you can confidently track changes, collaborate, and
          bring your projects to life. Dive in, experiment, and embrace the power
          of Git today!
        </p>
      </article>
    </div>
  );
};

export default GettingStartedWithGit;
